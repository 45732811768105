import React, { useContext, useState, useEffect, useRef } from "react";
import "./RigidBoxContainer.css";
import DropDownFields from "../reusable/DropDownFields";
import { DimensionUnitOptions } from "../DropDownOptions/DimensionUnitOptions";
import { PaperBagOptions } from "../DropDownOptions/boxClosureTypes/PaperBagOptions";
import { transportOptions } from "../DropDownOptions/TransportOptions";
import { PaperBagGSM } from "../DropDownOptions/PaperBagGSM";
import { regionOptions } from "../DropDownOptions/RegionOptions";
import { QISOrdersContext } from "../context/QISOrdersContext";
import { LaminationOptions } from "../DropDownOptions/LaminationOptions";
import InputTextField from "../reusable/InputTextField";
import { qisOrderDetails } from "../apis/apiClients";
import { toast } from "react-toastify";
import { UniqueIdContext } from "../context/UniqueIdContext";
import QisOrdersDisplay from "../reusable/QisOrdersDisplay";
import QuotationPriceDisplay from "../components/QuotationPriceDisplay";
import { PaperTypesOptions } from "../DropDownOptions/PaperTypesOptions";
import QisApplicationsValue from "../DropDownOptions/QisApplicationsValue";
import OutputTables from "../BDA/OutputTables";
import Downloadbutton from "../components/button";
import { HandleOptions } from "../DropDownOptions/HandleOptions";
import RegionModal from "../modals/RegionModal";
import { HandleAllClosureOptions } from "../DropDownOptions/HandleAllClosuresOptions";

const PaperBagContainer = () => {
  const quotationRef = useRef(null);
  const [displayQuotation, setDisplayQuotation] = useState(false);
  const [displayOrderDetails, setDisplayOrderDetails] = useState(false);
  const [regionModal, setRegionModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const { setPrice1, setPrice2, setPrice3 } = useContext(UniqueIdContext);
  useEffect(() => {
    setTimeout(() => {
      if (displayQuotation && quotationRef.current) {
        quotationRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 1000);

    setTimeout(() => {
      if (displayOrderDetails && quotationRef.current) {
        quotationRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 1000);
  }, [displayQuotation, displayOrderDetails]);
  const {
    product,
    setProduct,
    error,
    setError,
    clientName,
    setClientName,
    clientEmail,
    setClientEmail,
    clientContact,
    setClientContact,
    clientAddress,
    setClientAddress,
    saleDate,
    setSaleDate,
    setBoxType,
    fbbPaperType,
    setFbbPaperType,
    handleAllClosureType,
    sethandleAllClosureType,
    closure,
    setClosure,
    asspassPaper,
    setAsspassPaper,
    asspassQuality,
    setAsspassQuality,
    boardThickness,
    setBoardThickness,
    kraftQuality,
    setKraftQuality,
    cuffSize,
    setCuffSize,
    handle,
    setHandle,
    dim,
    setDim,
    dimUnit,
    setDimUnit,
    length,
    setLength,
    breadth,
    setBreadth,
    height,
    setHeight,
    qtt1,
    setQtt1,
    qtt2,
    setQtt2,
    qtt3,
    setQtt3,
    quality,
    setQuality,
    paperBagPaperType,
    setPaperBagPaperType,
    transport,
    setTransport,
    region,
    setRegion,
    cavity,
    setCavity,
    doubleSide,
    setDoubleSide,
    lamination,
    setLamination,
    applications,
    setApplications,
    applicationsValue,
    setApplicationsValue,
    corrugatedInnerPaper, 
    setCorrugatedInnerPaper,
    corrugatedOuterPaper,
    setCorrugatedOuterPaper,
    foodGradePaperColour, 
    setFoodGradePaperColour,
    foodGradePaperGSM, 
    setFoodGradePaperGSM,
    foodGradePrintingType,
    setFoodGradePrintingType,
    printingColours, 
    setPrintingColours,
    printingColoursInsidePaper,
    setPrintingColoursInsidePaper,
  } = useContext(QISOrdersContext);

  const handleRegionModal = () => {
    setRegionModal((prevShowModal) => !prevShowModal);
  };

  const employeeId = localStorage.getItem("uniqueId");

  const resetApplicationsValue = (appName) => {
    setApplicationsValue((prevApplicationsValue) => ({
      ...prevApplicationsValue,
      [appName]: null,
    }));
  };

  const handleApplicationChange = (event) => {
    const { name, checked } = event.target;
    setApplications((prevApplications) => ({
      ...prevApplications,
      [name]: checked,
    }));
    if (!checked) {
      resetApplicationsValue(name);
    }
  };

  const boxType = "paperBag";

  const handleSubmit = async (e) => {
    e.preventDefault();
    setDisplayOrderDetails(true);
    try {
      const res = await qisOrderDetails(
        clientName,
        clientEmail,
        boxType,
        product,
        closure,
        kraftQuality,
        asspassPaper,
        asspassQuality,
        cuffSize,
        boardThickness,
        dimUnit,
        handle,
        dim,
        qtt1,
        qtt2,
        qtt3,
        transport,
        quality,
        paperBagPaperType,
        region,
        applications,
        applicationsValue,
        employeeId,
        cavity,
        doubleSide,
        lamination,
        fbbPaperType,
        handleAllClosureType,
        corrugatedInnerPaper,
        corrugatedOuterPaper,
        foodGradePaperGSM,
        foodGradePrintingType,
        foodGradePaperColour,
        printingColours,
        printingColoursInsidePaper
      );
      if (res.status === 200) {
        setDisplayQuotation(true);
        toast.success("Quotation Generated successfully!");

        const price1 = res.data.first;
        setPrice1(price1);

        const price2 = res.data.second;
        setPrice2(price2);

        const price3 = res.data.third;
        setPrice3(price3);
      }
      setTableData(res.data);
      console.log(res.data);
      return res.data;
    } catch (error) {
      console.log("Errorrr");
      toast.error("Failed to Generate the Quotation!");
      console.log(error);
    }
  };

  return (
    <div>
      <div className="outerContainer">
        <div className="container">
          <div className="formTitle">Client & Order Details</div>
          <div className="formDetails">
            <form onSubmit={handleSubmit} action="POST" class="form">
              {/* <div>
                <div className="input-box">
                  <InputTextField
                    value={clientName}
                    setValue={setClientName}
                    label={"Client Name"}
                    placeholder={"Enter Client Name"}
                  />
                </div>

                <div className="input-box">
                  <InputTextField
                    value={clientEmail}
                    label={"Client Email"}
                    setValue={setClientEmail}
                    placeholder={"Enter Email Address"}
                  />
                </div>
              </div>

              <div className="input-box">
                <InputTextField
                  value={clientAddress}
                  setValue={setClientAddress}
                  placeholder={"Enter Client Address"}
                  label={"Client Address"}
                />
              </div>

              <div className="column">
                <div className="input-box">
                  <InputTextField
                    value={product}
                    setValue={setProduct}
                    label={"Product"}
                    placeholder={"Enter Product"}
                  />
                </div>
              </div> */}

              <div className="column">
                <div className="input-box">
                  <label>Paper Bag Closure</label>

                  <DropDownFields
                    options={PaperBagOptions}
                    value={closure}
                    setValue={setClosure}
                  />
                </div>

                <div className="input-box">
                  <label>Dimension Unit</label>
                  <DropDownFields
                    options={DimensionUnitOptions}
                    value={dimUnit}
                    setValue={setDimUnit}
                  />
                </div>
              </div>
              {closure === "paperBag" && (
                <div className="input-box">
                  <label>Handle</label>
                  <DropDownFields
                    options={HandleOptions}
                    value={handle}
                    setValue={setHandle}
                  />
                </div>
              )}

              <div className="column">
                <div className="input-box">
                  <InputTextField
                    value={length}
                    setValue={(value) => {
                      setLength(value);
                      setDim(`${value}*${breadth}*${height}`);
                    }}
                    label={"Length"}
                    placeholder={"Enter Length"}
                  />
                </div>
                <div className="input-box">
                  <InputTextField
                    value={breadth}
                    setValue={(value) => {
                      setBreadth(value);
                      setDim(`${length}*${value}*${height}`);
                    }}
                    label={"Breadth"}
                    placeholder={"Enter Breadth"}
                  />
                </div>
                <div className="input-box">
                  <InputTextField
                    value={height}
                    setValue={(value) => {
                      setHeight(value);
                      setDim(`${length}*${breadth}*${value}`);
                    }}
                    label={"Height"}
                    placeholder={"Enter Height"}
                  />
                </div>
              </div>

              <div className="column">
                <div className="input-box">
                  <InputTextField
                    value={qtt1}
                    setValue={setQtt1}
                    label={"Quantity 1"}
                    placeholder={"Quantity 1"}
                    required
                  />
                </div>
                <div className="input-box">
                  <InputTextField
                    value={qtt2}
                    setValue={setQtt2}
                    label={"Quantity 2"}
                    placeholder={"Quantity 2"}
                  />
                </div>
                <div className="input-box">
                  <InputTextField
                    value={qtt3}
                    setValue={setQtt3}
                    label={"Quantity 3"}
                    placeholder={"Quantity 3"}
                  />
                </div>
              </div>

              <div className="column">
                <div className="input-box">
                  <label>Transport</label>
                  <DropDownFields
                    value={transport}
                    setValue={setTransport}
                    options={transportOptions}
                  />
                </div>
                <div className="input-box">
                  <label>Paper GSM</label>
                  <DropDownFields
                    value={quality}
                    setValue={setQuality}
                    options={PaperBagGSM}
                  />
                </div>
              </div>

              <div className="input-box">
                <label>Paper Types</label>
                <DropDownFields
                  value={paperBagPaperType}
                  setValue={setPaperBagPaperType}
                  options={PaperTypesOptions}
                />
              </div>

              <div className="column">
                <div className="input-box">
                  <label>Region</label>
                  <DropDownFields
                    value={region}
                    setValue={setRegion}
                    options={regionOptions}
                  />
                </div>
                <i
                  onClick={handleRegionModal}
                  id="infoId"
                  className="fa-solid fa-circle-info"
                ></i>
                {regionModal ? (
                  <RegionModal showModal={handleRegionModal} />
                ) : (
                  ""
                )}
              </div>
              <div>
                <label>Lamination</label>
                <DropDownFields
                  value={lamination}
                  setValue={setLamination}
                  options={LaminationOptions}
                />
              </div>

              <div className="applicationsGridContainer">
                <label htmlFor="applications">Applications :</label>
                <div className="applicationsGrid">
                  <QisApplicationsValue
                    applications={applications}
                    handleApplicationChange={handleApplicationChange}
                  />
                </div>
              </div>

              <button>Magic Button ✨</button>
              <Downloadbutton>
                
              </Downloadbutton>
            </form>
          </div>
          {displayOrderDetails ? <QisOrdersDisplay boxType={boxType} /> : ""}
          {displayQuotation ? (
            <QuotationPriceDisplay qtt1={qtt1} qtt2={qtt2} qtt3={qtt3} />
          ) : (
            ""
          )}
          {displayQuotation ? (
            <OutputTables
              tableData={tableData}
              qtt1={qtt1}
              qtt2={qtt2}
              qtt3={qtt3}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default PaperBagContainer;
